import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "電子カルテの経験から、複雑なプロダクトデザインのアプローチを考える",
  "date": "2018-05-18T04:00:00.000Z",
  "slug": "entry/2018/05/18/130000",
  "tags": ["medley"],
  "hero": "./2018_05_18.png",
  "heroAlt": "karte デザイン"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。プレスリリースや前回の平山のブログでも紹介がありました、患者とつながるクラウド型電子カルテ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/karte"
      }}>{`CLINICS カルテ`}</a>{`」のデザインを担当しているマエダです。`}</p>
    <p>{`この電子カルテは、医療情報という複雑かつ独特なデータを扱うため、これまで自分自身が取り組んで来たような Web サービスとは違ったデザインのアプローチが必要でした。`}</p>
    <p>{`今回は、そんなデザイナーの苦悩と葛藤についてお話します。医療に限らず、複雑な業務フローの業界でデザインに悩む人のお役に立てれば嬉しいです。`}</p>
    <h1>{`デザインに取り掛かる前の準備`}</h1>
    <p>{`CLINICS カルテは「日医標準レセプトソフト（ORCA）」を完全内包しているカルテなのですが、医療にかなり詳しい人でないと、「レセプトソフトって？」というところから疑問ですよね。`}</p>
    <p>{`自分も同様で、レセプト？オーダー？DO 処方？...など医療の用語がわからない状態だったので、医療知識の理解や業務フローなどを知るところからプロジェクトに入り始めました。`}</p>
    <p>{`デザイン業務を行う前に、医療事務の書籍を読んだり、医療事務がどのようにレセプト業務を行っているのかを学ぶことで、どのような UI が適しているかなど掴んでいきました。`}</p>
    <p>{`ちなみに、皆さんが医療機関で受診した時に診療費の一部負担して支払われると思いますが（よく「3 割負担」など聞くこともあるかもしれません）残りの診療費については、 加入している医療保険の保険者（健康保険組合など）が支払います。`}</p>
    <p>{`この診療費の請求のために発行する明細を診療報酬明細書（レセプト） といい、レセプトを作成し診療報酬を請求する業務のことをレセプト業務と言います。`}</p>
    <p>{`そのレセプトを作成するための専用システムを「レセプトソフト」と言いますが、数あるレセプトソフトの中でも、日本医師会がオープンにして提供している「日医標準レセプトソフト（ORCA）」を、CLINICS カルテは内包しています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://clinics.medley.life/karte"
      }}><img parentName="a" {...{
          "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180516/20180516184514.png",
          "alt": "f:id:medley_inc:20180516184514p:plain",
          "title": "f:id:medley_inc:20180516184514p:plain"
        }}></img></a></p>
    <p>{`実際に ORCA を使ってみると、レセプト業務を知らない初心者には難易度が高い操作性に戸惑い、ウェブデザイン歴 14 年のマエダも機能ひとつひとつ理解するのに苦しめられました。`}</p>
    <p>{`Web サービスの管理ツールの利用に慣れていると、操作順序だったりボタンをクリックした後の挙動についてある程度予測できるのですが、ORCA はぜんぜん予測できない...。`}</p>
    <p>{`そんなときに大活躍したのが、超大作 1400 ページ程もある ORCA の操作マニュアル。人生でこんなに読み込んだマニュアルはないと断言できるほど、カルテをデザインした際に大変重宝しました。`}</p>
    <p>{`最初は操作に非常に大変でしたが、業務フローを理解したうえで、操作に慣れてくると ORCA の UI が請求業務をする上で理にかなっていることも理解できて、UI の奥深さにあらためて気付かされました。`}</p>
    <p>{`ORCA は実際にダウンロードして試してみることができるので、興味のある方はぜひ触ってみてはいかがでしょうか。`}</p>
    <ul>
      <li parentName="ul">{`ORCA 日レセクライアント：`}<a parentName="li" {...{
          "href": "https://www.orca.med.or.jp/receipt/download/java-client/"
        }}>{`https://www.orca.med.or.jp/receip`}</a><a parentName="li" {...{
          "href": "https://www.orca.med.or.jp/receipt/download/java-client/"
        }}>{`t/download/java-client/`}</a></li>
      <li parentName="ul">{`ORCA 操作マニュアル：`}<a parentName="li" {...{
          "href": "https://manual.orca.med.or.jp/5.0/html/"
        }}>{`https://manual.orca.med.or.jp/5.0/html/`}</a></li>
    </ul>
    <p>{`また、医療機関にカルテ入力〜会計処理までのフローのヒアリングなども行い、カルテのデザインをする上での前提知識を蓄えたり、既存カルテの課題なども整理することで、CLINICS カルテをどのようなデザインにしていくか、徐々に UI 方針を固めていきました。`}</p>
    <h1>{`ようやくデザインへのアプローチのお話`}</h1>
    <p>{`さて、ここまできてやっとデザインについての話です。`}</p>
    <p>{`昨今の Web デザインはワイヤーを引いて、モックを Sketch 等のデザインツールで制作し、動作検証するためにプロトタイピングツールで触れるようにした後、システム実装に取り掛かるというのが一般的だと思います。しかし、カルテは画面ごとに細かい機能がたくさんあり、それぞれの機能についてデザインして、プロトタイピングして....という一連の作業を行うとデザイン業務だけで恐ろしいほどの工数がかかるのが目にみえてました。`}</p>
    <p>{`そこで私は、デザインに取り掛かるアプローチを普段と変えてみることにしました。`}</p>
    <h1>{`デザインするけどデザインツールは使わない`}</h1>
    <p>{`カルテはほぼコーディングだけでデザインしました。俗にいうインブラウザコーディングというやつでしょうか。`}</p>
    <p>{`これによりワイヤー → デザインツール → プロトタイピングという、一連の工程を大幅に短縮して開発することができました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180516/20180516184701.png",
        "alt": "f:id:medley_inc:20180516184701p:plain",
        "title": "f:id:medley_inc:20180516184701p:plain"
      }}></img></p>
    <p>{`カルテが動作する開発環境のほかに UI デザイン用の環境があります。UI デザインの環境でコーディングした HTML/CSS ファイルを開発環境に移植することができるため、エンジニアは UI 実装する手間も大幅に削減でき、システム開発に専念することができたのもよかった点です。`}</p>
    <p>{`またインタラクションも CSS で制御するようにしたため、動作の部分もデザイナー自身の思い描いたイメージに合うように調整できたので、制限もあるプロトタイピングツールを使うよりも実際の挙動に近いかたちで検証できたこともメリットでした。`}</p>
    <p>{`ちなみに`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/08/03/160000"
      }}>{`以前にマエダが書いた記事`}</a>{`として CLINICS アプリでデザイン言語システムを導入した事をお伝えしましたが、CLINICS カルテの UI は絶賛改善中のため、まだ導入はしていません。ある程度 UI 設計の軸が固まった段階で改めて整理したいとおもっています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180516/20180516184720.jpg",
        "alt": "f:id:medley_inc:20180516184720j:plain",
        "title": "f:id:medley_inc:20180516184720j:plain"
      }}></img></p>
    <h1>{`UI の方針を定義することの重要性`}</h1>
    <p>{`私自身これまで Web サービスをいくつも手がけてきましたが、カルテのデザインほど UI に悩まされた経験はありませんでした。`}</p>
    <p>{`これまで見てきた電子カルテは機能毎にいくつものポップアップが表示されたり、深い階層を辿ったりするため、かなり独自の UI になっているように思えました。さらに、医療機関によって個別にカスタマイズされていることが多く、裏をかえせば UI のあるべき姿の方針が開発側ではなく、ユーザである医療機関側に委ねられているように思えました。実際に医療機関にヒアリングをしても、UI へのこだわりを持っている方が多い印象を受けました。`}</p>
    <p>{`こうなると、電子カルテを使っている医師は職場を変わる度に新しい電子カルテの使い方を学びなおすということになります。また、医療機関間で情報連携しようと思った時も、データの保管形式などにバラツキが出てしまいます。`}</p>
    <p>{`そこで CLINICS カルテでは、開発者側が UI 方針をしっかり定義し一貫性をもった操作性を提供することが診療効率の改善にもつながると考え、UI 開発を重要視しています。
カスタマイズに慣れている医療機関からすると、こうしてプロダクト側から UI を提示されることは珍しいことだと思います。しかしカスタマイズをしない分、UI の良し悪しが導入の判断を左右するという自負を持ちながら、どのような UI が最適なのかを日々模索し続けています。`}</p>
    <p>{`見た目的には問題なくても、触ってみるとクリック数が多くなってしまったり、適切な導線設計をしたつもりが逆にユーザーを迷わせる導線になってしまったりと、機能ひとつとっても「作る → 検証 → 改善」の工程を幾度となく繰り返しながら、使い勝手向上と機能の両立を目指しています。右脳と左脳が行き来するため脳内キャッチボールが発生し、疲弊することもしばしばありましたが、その分想いの強いプロダクトとしてリリースできたことはデザイナーをしていてもなかなか巡り合うことのできない貴重な経験を得られたと思っています。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20180516/20180516184733.jpg",
        "alt": "f:id:medley_inc:20180516184733j:plain",
        "title": "f:id:medley_inc:20180516184733j:plain"
      }}></img></p>
    <h1>{`上流工程としてだけでなく運用フェーズのデザインの重要性`}</h1>
    <p>{`プロダクトに携わるデザイナーの役割がどんどん幅広くなってきている昨今、広範囲でプロダクトに携わらないと、本質を捉えたデザインができず、プロダクトの方向に迷いが生じてしまうことになるので、全体像を理解したうえでデザインにどう落とし込んで行くかが重要になってきます。`}</p>
    <p>{`デザイナーは上流工程だけでなく、システムがどういう設計で成り立っているのか踏まえ、実運用も理解することで、今後の改善やアイデアに活かせると思います。`}</p>
    <p>{`どこが課題でどれを優先して開発するかなどもデザインする上で重要で、それも踏まえて企画検討することがプロダクトとして良いものにできるかどうかに影響します。`}</p>
    <p>{`上流だけでは結局のところ表面的な部分だけの品質を重視してしまいがちですが、全体像を捉えることがプロダクト品質を底上げするようにしていくことの大切さを、このプロダクト開発に携わることで学ばせてもらっています。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`すでに実際にご利用いただいてる医療機関もあり、実運用からのフィードバックを経て日々進化している CLINICS カルテ。まだまだプロダクトとして未成熟な段階ではありますが、「医療の課題を解決する」ため、CLINICS カルテでは医療業務の課題解決をしていきます。`}</p>
    <p>{`実際に CLINICS カルテを利用する方は医療機関の方に限られてしまうのですが、興味のあるデザイナー・エンジニア・ディレクターの方はぜひこちらまでご応募おまちしております。`}</p>
    <iframe className="embed-card embed-webcard" style={{
      "display": "block",
      "width": "100%",
      "height": "155px",
      "maxWidth": "500px",
      "margin": "10px 0px"
    }} title="デザイナー＆エンジニア採用 | 株式会社メドレー" src="https://hatenablog-parts.com/embed?url=http%3A%2F%2Fwww.medley.jp%2Frecruit%2Fcreative.html" frameBorder="0" scrolling="no"></iframe>
    <cite className="hatena-citation"><a href="https://www.medley.jp/recruit/creative.html">www.medley.jp</a></cite>
    <p>{`次回は CLINICS カルテのエンジニア兼飲み友の苦悩と葛藤について発表いたしますので、ご期待ください！`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      